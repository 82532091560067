<template>
  <TagbyConditionalLink
    v-if="sns != null"
    :href="landingUrl"
    :classList="['d-flex', 'no-wrap', 'align-items-center']"
    :text="snsId"
    :stateList="['LINK', 'TEXT', 'COPY']"
  >
    <BAvatar
      v-if="sns != null"
      size="30"
      :src="sns.profile_img_url"
      class="t-mr-1"
    />
    <!-- {{ snsId }} -->
  </TagbyConditionalLink>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import {
  BAvatar,
  BLink,
} from 'bootstrap-vue'
import TagbyConditionalLink from '@/components/TagbyConditionalLink.vue'

export default defineComponent({
  components: {
    BAvatar,
    BLink,
    TagbyConditionalLink,
  },
  props: {
    sns: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const landingUrl = computed(() => {
      if (props.sns.landing_url != null) {
        return props.sns.landing_url
      }
      if (props.sns.service === 'NAVER_BLOG') {
        return `https://blog.naver.com/${props.sns.uid}/`
      }
      if (['INSTA_BASIC', 'INSTA_BIZ'].includes(props.sns.service)) {
        return `https://instagram.com/${props.sns.username}/`
      }
      return null
    })
    const snsId = computed(() => (['NAVER_BLOG'].includes(props.sns.service) ? props.sns.uid : props.sns.username))
    return {
      landingUrl,
      snsId,
    }
  },
})
</script>

<style scoped>
.t-mr-1 {
  margin-right: 5px;
}
</style>
