<template>
  <div :class="classList">
    <slot />
    <div v-if="mode === 'TEXT'">
      {{ text }}
    </div>
    <div v-else-if="mode === 'LINK'">
      <BLink
        :href="href"
        target="_blank"
      >
        {{ text }}
      </BLink>
    </div>
    <div
      v-else-if="mode === 'COPY'"
      class="copy-text"
      @click="copyText"
    >
      {{ text }}
    </div>
  </div>
</template>

<script>
import {
  defineComponent, ref, computed, onMounted, onUnmounted,
} from '@vue/composition-api'
import {
  BLink,
} from 'bootstrap-vue'
import useToast from '@/utils/toast'

export default defineComponent({
  components: {
    BLink,
  },
  props: {
    text: {
      type: [String, Number],
    },
    href: {
      type: String,
    },
    stateList: {
      type: Array,
    },
    classList: {
      type: Array,
    },
  },
  setup(props) {
    const {
      makeToast,
    } = useToast()

    const copyText = () => {
      window.navigator.clipboard.writeText(props.text)
      makeToast('primary', 'Copy Success', props.text?.toString())
    }

    const pressedLinkKey = ref(false)
    const pressedCopyKey = ref(false)

    const keyMode = computed(() => {
      if (pressedLinkKey.value) {
        return 'LINK'
      }
      if (pressedCopyKey.value) {
        return 'COPY'
      }
      return 'TEXT'
    })

    const mode = computed(() => {
      if (props.stateList.includes(keyMode.value)) {
        return keyMode.value
      }
      return 'TEXT'
    })

    const setKeyModeOnKeyDown = e => {
      if (e.key === 'Control' || e.key === 'Meta') {
        pressedLinkKey.value = true
      } else if (e.key === 'Alt') {
        pressedCopyKey.value = true
      }
    }

    const setKeyModeOnKeyUp = e => {
      if (e.key === 'Control' || e.key === 'Meta') {
        pressedLinkKey.value = false
      } else if (e.key === 'Alt') {
        pressedCopyKey.value = false
      }
    }

    onMounted(() => {
      window.addEventListener('keydown', setKeyModeOnKeyDown)
      window.addEventListener('keyup', setKeyModeOnKeyUp)
    })

    onUnmounted(() => {
      window.removeEventListener('keydown', setKeyModeOnKeyDown)
      window.removeEventListener('keyup', setKeyModeOnKeyUp)
    })

    return {
      mode,
      copyText,
    }
  },
})
</script>

<style scoped>
.copy-text {
  cursor: pointer;
  text-decoration-line: underline;
  text-decoration-style: dotted;
}
</style>
