<template>
  <div style="width: 90px;">
    {{ resolveDate(value) }}
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import useResolveValue from '@/utils/resolveValue'

export default defineComponent({
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { resolveDate } = useResolveValue()
    return {
      resolveDate,
    }
  },
})
</script>
