<template>
  <BLink
    :to="{ name: 'campaign-compt-active-detail', params: { idx: campaignIdx } }"
  >
    {{ campaignIdx }}
  </BLink>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BAvatar,
  BLink,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BAvatar,
    BLink,
  },
  props: {
    campaignIdx: {
      type: Number,
    },
  },
  setup() {
  },
})
</script>
